<mat-sidenav-container class="sidenav-container">
  <mat-sidenav
    mat-primary
    #drawer
    class="sidenav"
    fixedInViewport
    [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
    [mode]="(isHandset$ | async) ? 'over' : 'side'"
    [opened]="(isHandset$ | async) === false"
  >
    <br />
    <mat-toolbar>
      <img src="assets/images/logo.png" alt="" width="187" height="50" />
    </mat-toolbar>
    <br />
    <mat-divider></mat-divider>
    <mat-accordion>
      <!-- comienzo del codigo menu desplegable de empresa -->
      <mat-expansion-panel *ngFor="let itemEmpresa of menuModulosEmpresa" hideToggle>
        <mat-expansion-panel-header color="white" [routerLink]="itemEmpresa.url" *ngIf="verificarPermiso(rolUser, itemEmpresa.rol)">
          <mat-panel-title>
            <mat-icon class="main-icon">{{ itemEmpresa.icon }} </mat-icon>
            {{ itemEmpresa.name }}
          </mat-panel-title>
        </mat-expansion-panel-header>

        <mat-nav-list>
          <mat-expansion-panel *ngFor="let subItemEmpresa of menuItems" hideToggle>
            <mat-expansion-panel-header color="white" [routerLink]="subItemEmpresa.url" *ngIf="verificarPermiso(rolUser, subItemEmpresa.rol)">
              <mat-panel-title>
                <mat-icon class="main-icon">{{ subItemEmpresa.icon }} </mat-icon>
                {{ subItemEmpresa.name }}
              </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-nav-list>
              <div *ngIf="subItemEmpresa.name == 'CONSOLIDADOS'">
              <mat-nav-list>
                <ng-container *ngFor="let subItem of submenuModulosPago">
                  <a
                    *ngIf="verificarPermiso(rolUser, subItem.rol)"
                    mat-list-item
                    [routerLink]="'/' + subItem.url + '/' + subItem.url"
                  >
                    <mat-icon color="grey">{{ subItem.icon }}</mat-icon>
                    {{ subItem.name }}
                  </a>
                </ng-container>
              </mat-nav-list>
              <mat-nav-list>
                <ng-container *ngFor="let subItem of submenuModuloConsolidadoPago">
                  <a
                    *ngIf="verificarPermiso(rolUser, subItem.rol)"
                    mat-list-item
                    [routerLink]="'/' + subItem.url + '/' + subItem.url"
                  >
                    <mat-icon color="grey">{{ subItem.icon }}</mat-icon>
                    {{ subItem.name }}
                  </a>
                </ng-container>
              </mat-nav-list>
            </div>
              <div
                *ngIf="
                  subItemEmpresa.name != 'AGROFIRMA' &&
                  subItemEmpresa.name != 'PRESTAMOS' &&
                  subItemEmpresa.name != 'SII' &&
                  subItemEmpresa.name != 'CONSOLIDADOS' &&
                  subItemEmpresa.name != 'EGRESOS GRUPO FIRMA' &&
                  subItemEmpresa.name != 'GASTO PERSONAL' &&
                  subItemEmpresa.name != 'GARRIDO PROYECTOS'
                "
              >
                <ng-container *ngFor="let subitem of subMenuItems">
                  <a
                    *ngIf="verificarPermiso(rolUser, subitem.rol)"
                    mat-list-item
                    [routerLink]="'/' + subItemEmpresa.url + '/' + subitem.url"
                  >
                    <mat-icon color="grey">{{ subitem.icon }}</mat-icon>
                    {{ subitem.name }}
                  </a>
                </ng-container>
              </div>
              <div *ngIf="subItemEmpresa.name == 'PRESTAMOS'">
                <ng-container *ngFor="let subitem of subMenuItemsPrestamos">
                  <a
                    *ngIf="verificarPermiso(rolUser, subitem.rol)"
                    mat-list-item
                    [routerLink]="'/' + subItemEmpresa.url + '/' + subitem.url"
                  >
                    <mat-icon color="grey">{{ subitem.icon }}</mat-icon>
                    {{ subitem.name }}
                  </a>
                </ng-container>
              </div>

              <div *ngIf="subItemEmpresa.name == 'GASTO PERSONAL'">
                <ng-container *ngFor="let subitem of subMenuItemsGastoPersonal">
                  <a
                    *ngIf="verificarPermiso(rolUser, subitem.rol)"
                    mat-list-item
                    [routerLink]="'/' + subItemEmpresa.url + '/' + subitem.url"
                  >
                    <mat-icon color="grey">{{ subitem.icon }}</mat-icon>
                    {{ subitem.name }}
                  </a>
                </ng-container>
              </div>
              
              <div *ngIf="subItemEmpresa.name == 'SII'">
                <ng-container *ngFor="let subitem of subMenuItemsSii">
                  <a
                    *ngIf="verificarPermiso(rolUser, subitem.rol)"
                    mat-list-item
                    [routerLink]="'/' + subItemEmpresa.url + '/' + subitem.url"
                  >
                    <mat-icon color="grey">{{ subitem.icon }}</mat-icon>
                    {{ subitem.name }}
                  </a>
                </ng-container>
              </div>

              <div *ngIf="subItemEmpresa.name == 'EGRESOS GRUPO FIRMA'">
                <ng-container *ngFor="let subitem of subMenuEgreso">
                  <a
                    *ngIf="verificarPermiso(rolUser, subitem.rol)"
                    mat-list-item
                    [routerLink]="'/' + subItemEmpresa.url + '/' + subitem.url"
                  >
                    <mat-icon color="grey">{{ subitem.icon }}</mat-icon>
                    {{ subitem.name }}
                  </a>
                </ng-container>
              </div>

              <!-- <ng-template #elseBlock> -->

              <div *ngIf="subItemEmpresa.name == 'AGROFIRMA'">
                <ng-container *ngFor="let subitem of subMenuItemsAgrofirma">
                  <a
                    *ngIf="verificarPermiso(rolUser, subitem.rol)"
                    mat-list-item
                    [routerLink]="'/' + subItemEmpresa.url + '/' + subitem.url"
                  >
                    <mat-icon color="grey">{{ subitem.icon }}</mat-icon>
                    {{ subitem.name }}
                  </a>
                </ng-container>
              </div>
              <!-- Modulo Empresa Garrido Proyecto -->
              <div *ngIf="subItemEmpresa.name == 'GARRIDO PROYECTOS'">
                <ng-container *ngFor="let subitem of subMenuModuloGarridoProyectos">
                  <a
                    *ngIf="verificarPermiso(rolUser, subitem.rol)"
                    mat-list-item
                    [routerLink]="'/' + subItemEmpresa.url + '/' + subitem.url"
                  >
                    <mat-icon color="grey">{{ subitem.icon }}</mat-icon>
                    {{ subitem.name }}
                  </a>
                </ng-container>
              </div>

              <!-- Modulo Empresa Autos ya -->
              <div *ngIf="subItemEmpresa.name == 'AUTOS YA'">
                <ng-container *ngFor="let subitem of subMenuModuloAutosYa">
                  <a
                    *ngIf="verificarPermiso(rolUser, subitem.rol)"
                    mat-list-item
                    [routerLink]="'/' + subItemEmpresa.url + '/' + subitem.url"
                  >
                    <mat-icon color="grey">{{ subitem.icon }}</mat-icon>
                    {{ subitem.name }}
                  </a>
                </ng-container>
              </div>

            </mat-nav-list>
          </mat-expansion-panel>
        </mat-nav-list>
      </mat-expansion-panel>
      <!-- final  del codigo menu desplegable de empresa -->

      <!-- comienzo del codigo menu desplegable de ventas -->
      <mat-expansion-panel *ngFor="let item of menuModuloVentas" hideToggle>
        <mat-expansion-panel-header color="white" [routerLink]="item.url" *ngIf="verificarPermiso(rolUser, item.rol)">
          <mat-panel-title>
            <mat-icon class="main-icon">{{ item.icon }} </mat-icon>
            {{ item.name }}
          </mat-panel-title>
        </mat-expansion-panel-header>

        <mat-nav-list>
          <ng-container *ngFor="let subitem of submenuModulosVentas">
            <a
              *ngIf="verificarPermiso(rolUser, subitem.rol)"
              mat-list-item
              [routerLink]="'/' + subitem.url + '/' + subitem.url"
            >
              <mat-icon color="grey">{{ subitem.icon }}</mat-icon>
              {{ subitem.name }}
            </a>
          </ng-container>
        </mat-nav-list>
      </mat-expansion-panel>
      <!-- final  del codigo menu desplegable de ventas -->

      <!-- comienzo del codigo menu desplegable de cliente -->
      <mat-expansion-panel *ngFor="let item of menuModuloCliente" hideToggle>
        <mat-expansion-panel-header
          color="white"
          [routerLink]="'/' + item.url + '/' + item.url"
          *ngIf="verificarPermiso(rolUser, item.rol)"
        >
          <mat-panel-title>
            <mat-icon class="main-icon">{{ item.icon }} </mat-icon>
            {{ item.name }}
          </mat-panel-title>
        </mat-expansion-panel-header>
      </mat-expansion-panel>
      <!-- final  del codigo menu desplegable de cliente -->

      <!-- comienzo del codigo menu desplegable de compras -->
      <mat-expansion-panel *ngFor="let item of menuModuloCompras" hideToggle>
        <mat-expansion-panel-header
          color="white"
          [routerLink]="'/' + item.url + '/' + item.url"
          *ngIf="verificarPermiso(rolUser, item.rol)"
        >
          <mat-panel-title>
            <mat-icon class="main-icon">{{ item.icon }} </mat-icon>
            {{ item.name }}
          </mat-panel-title>
        </mat-expansion-panel-header>
      </mat-expansion-panel>
      <!-- final  del codigo menu desplegable de compras -->

      <!-- comienzo del codigo menu desplegable de inventario -->
      <mat-expansion-panel *ngFor="let item of menuModuloInventario" hideToggle>
        <mat-expansion-panel-header color="white" [routerLink]="item.url" *ngIf="verificarPermiso(rolUser, item.rol)">
          <mat-panel-title>
            <mat-icon class="main-icon">{{ item.icon }} </mat-icon>
            {{ item.name }}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <mat-nav-list>
          <ng-container *ngFor="let subItem of submenuModulosInventario">
            <a
              *ngIf="verificarPermiso(rolUser, subItem.rol)"
              mat-list-item
              [routerLink]="'/' + item.url + '/' + subItem.url"
            >
              <mat-icon color="grey">{{ subItem.icon }}</mat-icon>
              {{ subItem.name }}
            </a>
          </ng-container>
        </mat-nav-list>
      </mat-expansion-panel>
      <!-- final  del codigo menu desplegable de inventario -->

      <!-- comienzo del codigo menu desplegable de Recaudacion -->
      <mat-expansion-panel
        *ngFor="let item of menuModuloRecaudacion"
        hideToggle
      >
        <mat-expansion-panel-header color="white" [routerLink]="item.url" *ngIf="verificarPermiso(rolUser, item.rol)">
          <mat-panel-title>
            <mat-icon class="main-icon">{{ item.icon }} </mat-icon>
            {{ item.name }}
          </mat-panel-title>
        </mat-expansion-panel-header>
      </mat-expansion-panel>
      <!-- final  del codigo menu desplegable de Recaudacion -->

      <!-- comienzo del codigo menu desplegable de Consolidado Bancario -->
      <mat-expansion-panel
        *ngFor="let item of menuModulosConsolidadoBancario"
        hideToggle
      >
        <mat-expansion-panel-header color="white" [routerLink]="item.url" *ngIf="verificarPermiso(rolUser, item.rol)">
          <mat-panel-title>
            <mat-icon class="main-icon">{{ item.icon }} </mat-icon>
            {{ item.name }}
          </mat-panel-title>
        </mat-expansion-panel-header>
      </mat-expansion-panel>
      <!-- comienzo del codigo menu desplegable de Consolidado Bancario -->

      <!-- comienzo del codigo menu desplegable de SolicitudOrden de compra  -->
      <mat-expansion-panel
        *ngFor="let item of menuSolicitudOrdenCompra"
        hideToggle
      >
        <mat-expansion-panel-header color="white" [routerLink]="item.url" *ngIf="verificarPermiso(rolUser, item.rol)">
          <mat-panel-title>
            <mat-icon class="main-icon">{{ item.icon }}</mat-icon>
            {{ item.name }}
          </mat-panel-title>
        </mat-expansion-panel-header>
      </mat-expansion-panel>

      <!--línea divisora-->
      <div class="mat-expansion-panel-divider"></div>

      <!-- comienzo del codigo menu desplegable de PagoOrden de compra  -->
      <mat-expansion-panel *ngFor="let item of menuPagoOrdenCompra" hideToggle>
        <mat-expansion-panel-header color="white" [routerLink]="item.url" *ngIf="verificarPermiso(rolUser, item.rol)">
          <mat-panel-title>
            <mat-icon class="main-icon">{{ item.icon }}</mat-icon>
            {{ item.name }}
          </mat-panel-title>
        </mat-expansion-panel-header>

       <!--  <mat-nav-list>
          <a
            *ngFor="let subItem of submenuModuloConsolidadoPago"
            mat-list-item
            [routerLink]="'/' + subItem.url + '/' + subItem.url"
          >
            <mat-icon color="grey">{{ subItem.icon }}</mat-icon>
            {{ subItem.name }}
          </a>
        </mat-nav-list> -->
      </mat-expansion-panel >
      <!-- comienzo del codigo menu desplegable de configuracion -->
      <mat-expansion-panel *ngFor="let item of menuModulosConfig" hideToggle>
        <mat-expansion-panel-header color="white" [routerLink]="item.url" *ngIf="verificarPermiso(rolUser, item.rol)">
          <mat-panel-title>
            <mat-icon class="main-icon">{{ item.icon }} </mat-icon>
            {{ item.name }}
          </mat-panel-title>
        </mat-expansion-panel-header>

        <mat-nav-list>
          <ng-container *ngFor="let item of submenuModulosConfig">
            <a
              *ngIf="verificarPermiso(rolUser, item.rol)"
              mat-list-item
              [routerLink]="'/' + item.url + '/' + item.url"
            >
              <mat-icon color="grey">{{ item.icon }}</mat-icon>
              {{ item.name }}
            </a>
          </ng-container>
        </mat-nav-list>
      </mat-expansion-panel>
      <!-- final  del codigo menu desplegable de configuracion -->
    </mat-accordion>
  </mat-sidenav>
  <mat-sidenav-content>
    <mat-toolbar color="primary">
      <button
        type="button"
        aria-label="Toggle sidenav"
        mat-icon-button
        (click)="drawer.toggle()"
      >
        <mat-icon aria-label="mostrar menu lateral">menu</mat-icon>
      </button>

      <!-- ********** Inicio Menú Administación**********-->
      <div
        *ngIf="verificarPermiso(rolUser, ['Administrador'])"
        hideToggle
        class="select-nav"
        style="font-size: 0.8rem"
      >
        <div *ngFor="let item of menuItemsAdmin">
          <mat-select placeholder="{{ item.name }}" class="select-nav2">
            <mat-option *ngFor="let subitem of subMenuItemsAdmin">
              <a
                mat-list-item
                [routerLink]="'/' + item.url + '/' + subitem.url"
              >
                {{ subitem.name }}
                <mat-icon color="grey">{{ subitem.icon }}</mat-icon>
              </a>
            </mat-option>
          </mat-select>
        </div>
      </div>

      <!-- ********** Fin Menú Administación **********-->

      <span class="example-spacer"></span>
      <span>PLATAFORMA FINANZAS - GRUPO FIRMA</span>
      <span class="example-spacer"></span>
      <span style="font-size: 0.9rem; margin-right: 1rem">
         <!-- {{ auth.usuario.nombreUsuario }} -->
         {{auth.usuario.nombre}} {{auth.usuario.apellido}}
      </span>
      <!-- <button mat-raised-button color="accent" style="margin-right: 1rem;" (click)="volverVistaAtigua()">
                <mat-icon>reply</mat-icon> volver
      </button> -->
      <button mat-raised-button color="accent" (click)="cerrarSesion()">
        <mat-icon>logout</mat-icon> Salir
      </button>
    </mat-toolbar>
    <div class="container-page">
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
